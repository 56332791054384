<template>
  <div>
    <v-main style=""> 
      <v-container fluid > 
        <v-card min-height="100vh" rounded="lg" >
          <v-card-text>
            <v-row class="mx-0">
              <v-col>
                <template v-slote:top>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <Search 
                      @submit-data="submitSearch"
                      @clear-data="clearSearch"
                      :searchParam="searchParam"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <h1>Video Count:  {{ countVideo }}</h1>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <h1>Time to close:  {{ countdown }} (sec)</h1>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <template>
                  <v-row v-if="arUrl.length > 0">
                    
                    <v-col v-for="item in arUrl" :key="item.server_id" cols="12" sm="6" md="3" xs="12" lg="3">
                      <div style="background-color: lightgray; margin: 0px !important; padding: 0px !important;"> 
                          <h2 style="padding: 5px 10px;">ServerID: {{ item.server_id }} : {{mysubString(item.video_url) }}</h2>
                          <iframe :src=convertUrl(item.video_url) title="Iframe Example" width="100%"></iframe>
                        </div>  
                    </v-col>
                    
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import "@/assets/css/report.css";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
    Search: () => import("./_components/search.vue"),
  },
  data: () => ({
    data: [],
    startIndex: 0,
    interval: 0,
    intervalTime: 0,
    arUrl: [],
    countdown: 0,
    countVideo: 0,
    searchParam: {
        amount: 5,
        duration: 60,
        server_id: ""
      }
  }),

  computed: {
    ...mapGetters("$_dashboard", [
      "getListResult"
    ]),
  },
  async created() {
    await this.getData();
  },
  methods: {
    moment,
    async getData() {
      try {
        this.loader = true;
        let preData = {
          tv: parseInt(process.env.VUE_APP_TV)
        };
        await this.fetchListResult(preData);
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    submitSearch: function (params) {
      this.search = params;
      this.randomUrl(parseInt(params.amount),parseInt(params.duration), params.server_id);
    },
    clearSearch: function(){
      clearInterval(this.interval);
      clearInterval(this.intervalTime);
      this.interval = 0;
      this.intervalTime = 0;
      this.arUrl = [];
      this.searchParam = {
        amount: 5,
        duration: 60,
        server_id: ""
      };
      this.countdown= 0;
      this.countVideo= 0;
    },
    convertUrl(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    mysubString(str){
      return str.substring(
          str.indexOf("//") + 2, 
          str.indexOf(".")
      );
    },
    randomUrl(amount, duration, server_id){
      let arr = this.getListResult;
      if(arr.length >0){
      if(server_id != ""){
        this.arUrl = arr.filter(e => e.server_id == parseInt(server_id));
        if(this.arUrl.length == 0){
          this.arUrl = arr.filter(e => e.video_url.includes(server_id));
        }
        this.countVideo = this.arUrl.length;
        this.countdown = 0;
        clearInterval(this.interval);
        clearInterval(this.intervalTime);
      }else{
        this.arUrl= [];
        let setTime = 1000*duration;
        let time = duration;
        // let resetTime = time;
        let n = amount;
        if(n > arr.length){
          n = arr.length;
        }
          for(let i = 0; i<  n ;i++){
            this.arUrl.push(arr[i]);
          }
          this.countVideo = this.arUrl.length;
          this.startIndex = this.startIndex + n;
          clearInterval(this.interval);
          this.countdown = 0;
          clearInterval(this.intervalTime);
          if(this.countdown <= time){
            this.intervalTime = setInterval(() => {
              this.countdown++;
            }, 1000);
          }else{
            this.countdown = 0;
            clearInterval(this.intervalTime);
          }
          this.interval = setInterval(() => {
            let isReset = 0;
          // if(this.countdown < time){
                this.countdown = 0;
                clearInterval(this.intervalTime);
                if(this.countdown <= time){
                  this.intervalTime = setInterval(() => {
                    this.countdown++;
                  }, 1000);

                }else{
                  this.countdown = 0;
                  clearInterval(this.intervalTime);
                }
                let len = arr.length -1;
        
                this.arUrl= [];
                for(let i = 0; i<  n ;i++){
                    if(i+this.startIndex <= len){
                      this.arUrl.push(arr[i+this.startIndex]);
                    }
                    
                    if(this.startIndex >= len){
                      this.startIndex =0; 
                        isReset = 1;
                        break
                    }
                }
                
                if(this.arUrl.length == 0){
                      for(let i = 0; i<  n ;i++){
                        this.arUrl.push(arr[i]);
                      }
                      this.startIndex = n;
                }
                this.countVideo = this.arUrl.length;
                if(isReset ==0){
                  this.startIndex = this.startIndex + n;
                }
                this.countdown++;
            
              
          }, setTime);
    
        }
      }
    },
    ...mapMutations("$_dashboard", [
      "LIST_RESULT"
    ]),
    ...mapActions("$_dashboard", ["fetchListResult"]),
  },
};
</script>

<style></style>
