// update data over statement
const LIST_RESULT = (state, data) => {
    state.listResult = data;
};
//
const COUNT_LIST_RESULT = (state, data) => {
    state.countListResult = data;
};
const LOADING = (state, data) => {
    state.loading = data;
};
const UPDATE_CURRENT_DATA = (state, data) => {
    state.currentList = data
 };
const UPDATE_FORM_URL = (state, data) => {
    state.currentList = data;
}
const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentList = {
            _id: null,
            server_id: null,
            video_url: null,
            tv: null,
        }
    }
    state.popUp = data;
};
const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};
//
export default {
    LIST_RESULT,
    COUNT_LIST_RESULT,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_DATA,
    UPDATE_FORM_URL,
    DELETE_ITEMS
};