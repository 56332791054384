export default {
	ticket_id : "សំបុត្រលេខ",
	game_id: "ហ្គេមលេខ",
	date : "កាលបរិច្ឆេទ",
	type_betting : "ប្រភេទចាក់",
	bet_point : "ផលសង",
	win_loss: "ឈ្នះ/ចាញ់",
	cancel :"បោះបង់",
	print:"ព្រីន"
};
