import english from './english';
import khmer from './khmer';
import lao from './lao';
import thai from './thai';

export default {
	english,
	khmer,
	lao,
	thai
};

