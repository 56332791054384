/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
//
let request = new Request;
//
const fetchListResult = async ({ commit }, preData) => {
    
    const response = await request.post({
        url: '/getTv', data:preData
    });
    console.log("response",response.data);
    if (response.data.success) {
       
        commit('LIST_RESULT', response.data.data);
    }
};
//
export default {
    fetchListResult,
};