import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import axios from "axios";
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...routes]
})

router.beforeEach((to, from, next) => {
    var myParam = window.location.search.split('token=')[1]
    if(myParam){
        Vue.$cookies.set("l80token", decodeURI(myParam),0);
    }
    
    const token = Vue.$cookies.get('l80token');
    //console.log("TOKEN..in index",token);

    
    if(myParam){
        axios.get( process.env.VUE_APP_API_URL, {
        headers: {
            "Authorization" : token 
        }
        })
        .then(response => {
            //console.log('dammm....');
            if (response.data.code==1) {
                //console.log('dammm....');
                const { user_name, balance, currency_type } = response.data.data;
                Vue.$cookies.set("l80user_name",user_name);
                Vue.$cookies.set("l80balance", balance);
                Vue.$cookies.set("l80currency_type", currency_type);
         } 
        })
        .catch(error => {
            this.$toastr.e(`${error}`);
        })
    }
    

    // if (!token && to.name !== 'login') {
    //     next({ name: 'login' });
    // } else if ((token && to.name === 'login') || to.path === '/') {
    //     next({ name: '' });
    // } else {
    //     next();
    // }
    return next();
});

export default router
