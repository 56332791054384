export default {
	ticket_id : "ຈົດຫມາຍສະບັບ",
	game_id: "ອັນດັບ",
	date : "ວັນທີ",
	type_betting : "ປະເພດການພະນັນ",
	bet_point : "ຈຸດເດີມພັນ",
	win_loss: "ຊະນະ/ສູນເສຍ",
	cancel :"ຍົກເລີກ",
	print:"ພິມ"
};
