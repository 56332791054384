<!--Lotto main layout layout-->
<template>
    <div>
        <default-layout>
        </default-layout>
        <v-card class="overflow-hidden">
            <v-app-bar app class="app-bar-style p-0 m-0">
                <!-- menus button -->
                <menus-btn />
                <v-spacer></v-spacer>
                <!-- translate section -->
                <!-- <translate /> -->
                <!-- user information -->
               <p> {{$t('title.welcome')}} </p> <p class="user-info"> {{ label }} </p>
                <!--Log out-->
                <!-- <v-btn  x-small text @click="onLogout" title="logout">
                    <v-icon style="color: red;">mdi-power-standby</v-icon>
                </v-btn> -->
            </v-app-bar>
        </v-card>
    </div>
</template>

<script>
    import "@/assets/css/header.css";
    //import { mapActions, mapGetters } from "vuex";
    // import main layout
    import DefaultLayout from "@/layouts/default";
    //app bar menu
    import MenusBtn from "@/components/menusBtn.vue";

   // import Translate from "@/components/translate.vue";
    export default {
        components: { DefaultLayout, MenusBtn },
        data: () => ({
            menus: [
                { name: "TV", url: "/", },
                { name: "Video Url", url: "/win-result", },
            ],
            label: "serve1"
        }),
        // created() {
        //     this.getUserInfo();
        // },
        // computed: {
        //     ...mapGetters("$_modules", {
        //         userInfor: "getUserInfo",
        //     }),
        // },
        mounted() {
            this.label = process.env.VUE_APP_LABEL;
            console.log("process.env.LABEL",process.env.VUE_APP_LABEL);
        },
        // methods: {
        //     _goToRoute(url) {
        //         this.$router.push({ path: url }).catch()
        //     },
        //     connectSocket() {
        //         this.$connectSocket(this.$cookies.get('l80token'))
        //     },
        //     onLogout() {
        //         this.$socket.disconnect()
        //         this.$cookies.remove('l80token')
        //         window.close();
        //         // this.$router.push("/login").catch({})
        //     },
        //     currencyFormat(num) {
        //         num = parseFloat(num)
        //         return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        //     },
        //     getUserCurrency() {
        //         if (this.userInfor.currency_type === 1) {
        //             return "$"
        //         } else if (this.userInfor.currency_type === 2) {
        //             return "៛"
        //         } else if (this.userInfor.currency_type === 3) {
        //             return "฿"
        //         }
        //     },
        //     async getUserInfo() {
        //         await this.fetchUserInfo();
        //     },
        //     ...mapActions("$_myinfo", [
        //         "fetchMyInfo",
        //     ]),
        //     ...mapActions("$_modules", ["updateBalance", "updateUserInfo", "fetchUserInfo"]),
        // },
    };
</script>

<style lang="scss" scoped>
</style>