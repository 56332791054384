import actions from './actions';
import getters from './getters';
import mutations from './mutations';
//
const state = {
    listResult: [],
    countListResult: {
        objectCount: null,
    },
    loading: false,
    currentList:{
        _id:null,
        server_id:null,
        video_url:null
    },
    popUp: false,
    deleteItems: []
};
//
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};