<template>
  <div>
      <router-view  />
  </div>
</template>

<script>
    // import { mapGetters } from "vuex";
    import storeDashboard from "@/modules/dashboard/_store";
    import storeVideoURL from "@/modules/videoURL/_store";

    export default {
        name: "App",
        data() {
            return { isLoading: true };
        },
        created() {
            this.registerStore();
        },
        // computed: {
        //     ...mapGetters("$_modules", {
        //         darkmode: "getDarkMode",
        //     }),
        // },
        // mounted() {
        // //console.log('app..vue');
        //     setTimeout(() => {
        //     this.isLoading = false;
        //     }, 1300);
        // },
        methods: {
            registerStore() {
                const DASHBOARD = "$_dashboard";
                const VIDEOURL = "$_videoURL";
              

                if (!(DASHBOARD in this.$store._modules.root._children)) {
                    this.$store.registerModule(DASHBOARD, storeDashboard);
                }
                if (!(VIDEOURL in this.$store._modules.root._children)) {
                    this.$store.registerModule(VIDEOURL, storeVideoURL);
                }
            },
        },
    }
</script>
<style>
    @import url("https://fonts.googleapis.com/css2?family=Battambang&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
HTML CSSResult Skip Results Iframe
EDIT ON
html, body {
    height: 100%!important;
    background-color:#3c3c3c;
}
.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#fff000;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #fff000;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #fff000;
  border-right:3px solid #fff000;
  border-radius:50%;
  animation:animateC11 2s linear infinite;
}
.span-loading
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate11 2s linear infinite;
}
.span-loading:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#fff000;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #fff000;
}
@keyframes animateC11
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate11
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}
</style>
