export default {
    search: 'ຊອກຫາ',
    permission_name: 'ຊື່​ອະ​ນຸ​ຍາດ​',
    role_name: 'ຊື່​ພາ​ລະ​ບົດ​ບາດ​',
    menu_name: 'ຊື່​ເມ​ນູ',
    description: 'ລາຍລະອຽດ',
    order: 'ຄໍາສັ່ງ',
    route_name: 'ຊື່​ເສັ້ນ​ທາງ',
    url_name: 'ຊື່ url',
    icon: 'ໄອຄອນ',
    parent: 'ຂໍ້ມູນຂ່າວສານ',
    permission:    'ການອະນຸຍາດ',
    visible: 'ເຫັນໄດ້',
    user_name: 'ຊື່ຜູ້ໃຊ້',
    bank_account: 'ບັນ​ຊີ​ທະ​ນາ​ຄານ7',
    user_role: 'ບົດບາດຂອງຜູ້ໃຊ້',
    password: 'ລະຫັດຜ່ານ7',
    newPassword:"ລະຫັດຜ່ານໃໝ່7",
    oldPassword:"ລະ​ຫັດ​ຜ່ານ​ເກົ່າ",
    betCurrency:"$",
    overLimit:"ຫຼິ້ນຕໍ່າກວ່າ ຫຼືເກີນຂີດຈຳກັດ"
};
