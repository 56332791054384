import actions from './actions';
import getters from './getters';
import mutations from './mutations';
//
const state = {
    listResult: []
};
//
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};