import Dashboard from '@/modules/dashboard';
//use independant layout
import LayoutDashboard from '@/layouts/dashboard';

const routes = [
    {
        path: '/home',
        component: LayoutDashboard,
        meta: {
            permission: 'master',
            requiresAuth: true
        },
        children: [
            {
                path: '',
                component: Dashboard,
                name: 'home'
            }
        ]
    }
];

export default routes;