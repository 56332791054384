export default {
	home: 'ផ្ទះ',
	// menu
	lotto: 'ឡូតូ',
	statement: 'របាយការណ៌',
	changepassword: 'ប្តូរពាក្យសម្ងាត់',
	logout:"ចាកចេញ",
	// submenu
	winResult: 'លទ្ធផលឈ្នះ',
	lottoResult: 'លទ្ធផល',
	withdrawStatement: 'របាយការណ៍ដកប្រាក់',
	depositStatement: 'របាយការណ៍ដាក់ប្រាក់',
	lottorule:'ជំនួយ',
	reportBetting:"របាយការណ៌ការភ្នាល់",
	reportDeposit:"របាយការណ៌ដាក់ប្រាក់",
	reportWithdraw:"របាយការណ៌ដកប្រាក់"

};
