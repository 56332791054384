export default {
	username: 'ຊື່ຜູ້ໃຊ້',
	password: 'ລະຫັດຜ່ານ',
	login: 'ເຂົ້າ​ສູ່​ລະ​ບົບ',
	contact_us: 'ຕິດ​ຕໍ່​ພວກ​ເຮົາ',
	//validation
	invalid: {
		username: 'ລະບຸຊື່ໜ້ອຍໜຶ່ງ!!'
	},
	//required
	required: {
		username: 'ກະລຸນາໃສ່ຊື່ຜູ້ໃຊ້ນີ້!!',
		password: 'ກະລຸນາໃສ່ຄໍາສັບຂອງທ່ານ!'
	},
	//error
	error: {
		login_failed: 'ຊື່ຜູ້ໃຊ້ ຫຼື ສະເພາະໃນລະບົບນີ້'
	}
};
