export default {
	home: 'Home',
	// menus
	lotto: 'Lotto',
	statement: 'Statement',
	changepassword: 'Change Password',
	logout: 'Exit',
	// submenu
	winResult: 'Win-Result',
	lottoResult: 'Result',
	withdrawStatement: 'Statement Withdraw',
	depositStatement: 'Statement Deposit',
	lottorule:'Rule',
	reportBetting:"Betting Statement",
	reportDeposit:"Deposit Statement",
	reportWithdraw:"Withdraw Statement"
};
