export default {
	receipt: 'ໃບຮັບເງິນ',
	invoice_no: 'ໝາຍເລກໃບເກັບເງິນ',
	doctor: 'ទន្តពេទ្យ',
	date: 'ວັນທີ',
	gender: 'ភេទ',
	patient: 'អ្នកជំងឺ',
	age: 'អាយុ',
	phone_number: 'លេខទូរស័ព្ទ',
	description: 'សេវា',
	qty: 'បរិមាណ',
	unit_price: 'តម្លៃ',
	subtotal: 'តម្លៃ​',
	discount: 'បញ្ចុះតម្លៃ',
	total: 'ທັງໝົດ',
	total_price: 'តម្លៃសរុប',
	notes: 'ບັນທຶກ',
	deposit: 'ເງິນຝາກ'
};
