<template>
  <!-- eslint-disable -->
  <div class="menu">
    <ul>
      <li>
        <a
          @click="getToRoute('/')"
          class="fontKhmer"
          :class="$route.name == 'home' ? 'active' : ''"
        >
          TV</a
        >
      </li>
      <li>
        <a
          @click="getToRoute('/video')"
          class="fontKhmer"
          :class="$route.name == 'video'  ? 'active' : '' "
        >
          Video Url</a
        >
      </li>
      
    </ul>
    
  </div>
 
</template>

<script>
/* eslint-disable */
export default {
  methods: {
    getToRoute(path) {
      this.$router.push({ path }).catch(() => { /* ignore */ });
    },
  },
};
</script>
<style scoped>
.menu {
  font-size: 16px;
  margin-left: -5px;
  position: relative;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif, FontKhmer;
}
.menu ul {
  list-style-type: none;
  margin: -3px 0px 0px 0px;
  padding: 0;
  overflow: hidden;
  border: none;
  background-color: #d3e4f6;
}
.menu ul li {
  float: left;
    background-color: #d3e4f6;
  padding: 0px 2px 0px 0px;
  margin : 5px 0px;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.menu ul li a.active,
.menu ul li a:hover {
  background-color: #4575cd;
  margin-top:-2px;
  color: #fff;
  cursor: pointer;
}
.menu ul li a {
  display: block;
  color: #234187;
  text-align: center;
  text-decoration: none;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 31px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
a {
  background-color: transparent;
}
</style>;
