export default {
    search: 'Search',
    permission_name: 'Permission Name',
    role_name: 'Role Name',
    menu_name: 'Menu Name',
    description: 'Description',
    order: 'Order',
    route_name: 'Route Name',
    url_name: 'URL Name',
    icon: 'Icon',
    parent: 'Parent',
    permission: 'Permission',
    visible: 'Visible',
    user_name: 'Username',
    bank_account: 'Bank Account',
    user_role: 'User Role',
    password: 'Password',
    newPassword:"New Password",
    oldPassword:"Old Password",
    betCurrency:"$",
    overLimit:"Bet Under / Over Limit",
    server_id:"Server ID",
    video_url:"Video URL",
};
