<template>
  <div>
    <v-main style=""> 
      <v-container fluid > 
        <v-card min-height="100vh" rounded="lg" >
          <v-card-text>
            <v-row class="mx-0">
              <v-col>
                <template v-slote:top>
                  <v-row>
                    <v-col cols="9" sm="9" md="9" class="mr-0 pr-0">
                      <v-toolbar-title class="pl-5 report-title">
                        {{$t("title.video_url")}}
                      </v-toolbar-title>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mr-0 pr-0">
                      <div class="between w-full mb-3 mt-0 for-screen-mobile">
                        <v-col cols="6" sm="6" md="4">
                          <v-btn
                            @click="onCreateDialog"
                            class="white--text btn-search-date"
                            color="blue darken-1"
                            depressed
                          >
                           Create
                          </v-btn>
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template>
                  <v-data-table
                    :headers="headers"
                    hide-default-header
                    :footer-props="footerProps"
                    :items="getListResult ? getListResult : []"
                    :server-items-length="
                      getListResult ? getListResult.length : 0
                    "
                    :loading="getLoading"
                    :sort-desc.sync="data.descendingFormat"
                    :sort-by.sync="data.sortByFormat"
                    :page.sync="data.page"
                    :items-per-page.sync="data.rows_per_page"
                    @update:page="getData"
                    @update:items-per-page="getData"
                    @update:sort-desc="getData"
                    :loading-text="$t('datatable.loadingData')"
                    class="elevation-1 data-table"
                  >
                   <template v-slot:header="{ props }">
                      <th v-for="head in props.headers" :key="head.no" class="header">{{ $t(head.text) }}</th>
                    </template>
                    <template v-slot:[`item`]="{ item }">
                      <tr>
                        <td>
                          {{
                            getListResult.indexOf(item) +
                            (data.page - 1) * data.rows_per_page +
                            1
                          }}
                        </td>
                        <td>{{ item.server_id }}</td>
                        <td>{{ item.video_url.substring(0, 67) }}</td>
                        <td>
                          <ActionComponents
                            :item="item"
                            :params="data"
                          />
                          <!-- <div>
                            <v-icon color="orange"
                                    small
                                    class="mr-2"
                                    @click="updateVideoUrl"
                            >mdi-pencil
                            </v-icon>

                            <v-icon color="red"
                                    small
                                    @click="alertDeleteDialog(item._id)"
                            > mdi-delete
                            </v-icon>

                          </div> -->
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
                <Dialog
                  :loading="loading"
                  :videoData="getCurrentList"
                  :dialog="getPopUp"
                  v-on:submit="handleSubmit"
                  v-on:onCloseDialog="closeDialogCreate"
                />
                <div v-if="dialogLogin">
    <v-dialog :value="dialogLogin" persistent width="60%" style="position: absolute; bottom: 0;">
      <v-card>
        <v-toolbar>
          
          <v-toolbar-title>
            Please Input OTP: 
          </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn  
            class="white--text mr2"
            color="green darken-1"
            @click="login">
              Login
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <Loading :visible="loading" /> -->
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-text-field
                  v-model="password"
                  label="OTP"
                  type="password"
                ></v-text-field>
              </v-row>

            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import "@/assets/css/report.css";
import { mapActions, mapGetters, mapMutations } from "vuex";

import moment from "moment";

export default {
  props: ["item"],
  components: {
    // Search: () => import("./_components/search.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
    ActionComponents: () => import("./_components/Action"),
  },
  data: () => ({
    headers : [
    { text: 'header.no', align: 'start', value: 'no' },
    { text: 'header.server_id', value: 'server_id' },
    { text: 'header.video_url', value: 'video_url' },
    { text: "Actions", value: "actions", sortable: false },
  ],
    data: {
      rows_per_page: 20,
      page: 1,
      start_date: "",
      end_date: "",
    },
    footerProps: {
      "items-per-page-options": [20, 50, 100, 200, 500],
    },
    loading:false,
    time: null,
    format: "YYYY-MM-DD",
    start_date: "",
    end_date: "",
    dialogLogin: false,
    password: "",
    confirmPass: ""
  }),
  watch: {
    fetchListResult: function () {
      this.$forceUpdate();
    },
  },

  computed: {
    ...mapGetters("$_videoURL", [
      "getListResult",
      "getCountListResult",
      "getLoading",
      "getPopUp",
      "getCurrentList",
      "getDeleteItems",
    ]),
  },
  async created() {
    //await this.getData();
  },
  methods: {
    moment,
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentList._id) {
        let data = {
          server_id: parseInt(this.getCurrentList.server_id),
          video_url: this.getCurrentList.video_url, 
          tv: parseInt(process.env.VUE_APP_TV)
        };
        await this.createFormURL({ vm: this, data: data });
        this.getCurrentList = {};
        this.fetchPopUp(false);
      } else {
        let data = {
          _id:this.getCurrentList._id,
          server_id: parseInt(this.getCurrentList.server_id),
          video_url: this.getCurrentList.video_url,
          tv: parseInt(process.env.VUE_APP_TV)
        };
        await this.updateFormURL({
          vm: this,
          data: data
        });
        this.fetchPopUp(false);
      }
      await this.getData();
      this.loading = false;
    },
    async onCreateDialog() {
      this.fetchPopUp(true)
    },
    closeDialogCreate() {
      this.getListResult = {};
      this.fetchPopUp(false);
    },
    // Gets the checkbox information from the child component
    checkboxValue: function (params) {
      this.check = params;
      this.data.start_date = params.start_date;
      this.data.end_date = params.end_date;
    },
    async getData() {
      try {
        this.loader = true;
        let preData = {
          tv: parseInt(process.env.VUE_APP_TV),
          //tv: 1,
        };
        await this.fetchListResult(preData);
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    async login(){
      if(this.password.toString() == this.confirmPass.toString()){
        await this.getData();
        this.dialogLogin = false;
        sessionStorage.setItem("confirm",1);
        console.log(sessionStorage.getItem("confirm"));
      }else{
        alert("Incorrect Password");
      }
    },
    ...mapMutations("$_videoURL", [
      "LIST_RESULT",
      "COUNT_LIST_RESULT",
      "LOADING",
      "UPDATE_POPUP",
      "UPDATE_CURRENT_DATA",
      "UPDATE_FORM_URL",
      "DELETE_ITEMS"
    ]),
    ...mapActions("$_videoURL", ["createFormURL","updateFormURL","fetchListResult","fetchPopUp","fetchCurrentList"]),
  },
  beforeMount() {
    // set default language cookie if langauge cookie is not exists
    const totp = require("totp-generator");
    let otpFromServer = totp("C3KM46XG2EP4ASGK", {
        digits: 6,
        period: 120,
      });
      console.log("otpFromServer",otpFromServer);
      this.confirmPass = otpFromServer;
    this.$nextTick(() => {
      if (
        sessionStorage.getItem("confirm") == 1
      ) {
        this.getData();
        this.dialogLogin = false;
      } else {
        this.dialogLogin = true;
      }
    });
  },
};
</script>

<style></style>
