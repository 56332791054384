export default {
    welcome: "Welcome",
    login:"Member Login",
    currencyEn:"USD",
    currencyKm:"Riel",
    bet_credit: "Credit",
    outstranding :"OutStanding",
    last_game:"Last Results",
    current_betting:"Current Betting",
    previous_betting:"Previous Betting",
    ticket:"Ticket",
    total:"Total",
    result:"Result",
    amount_bet:"Amount Bet",
    max_bet : "Max",
    min_bet : "Min",
    bet:"Bet",
    clear:"Clear",
    u_o:"Small / Large",
    range : "Range",
    statement:"Statement",
    changePassword:"Change Password",
    resultStatement:"Results Statement",
    successChangePassword:"Password Successfully Change",
    failChangePassword:" Something When wrong, fail to change password",
    no_data:"No data ...",
    betSmallType:"Under",
    betBigType:"Over",
    btnBetSmallType:"U",
    btnBetBigType:"O",
    not_enought_balance:"Not enough balance",
    bet_over_limit:"Bet over limit",
    video_url:"Video Url List",
    create_video_url:"Create Video URL",
    update_video_url:"Update Video URL",


};
