const getListResult = (state) => {
    return state.listResult;
};
//
const getCountListResult = (state) => {
    return state.countListResult;
};

const getLoading = (state) => {
    return state.loading;
};
const getPopUp = (state) => state.popUp;
const getCurrentList = (state) => state.currentList;
const getDeleteItems = (state) => state.deleteItems;
//
export default {
    getListResult,
    getCountListResult,
    getLoading,
    getCurrentList,
    getPopUp,
    getDeleteItems
};