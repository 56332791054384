export default {
	update: 'Update',
	delete: 'Delete',
	delete_all: 'Delete All',
	view: 'View',
	cancel: 'Cancel',
	yes: 'Yes',
	save: 'Save',
	detail: 'Detail',
	create: 'Create',
	upload_file: 'Upload File',
	clear: 'Clear',
	filter: 'Filter',
	local_leng: 'ខ្មែរ',
	create_invoice: 'Create Invoice',
	print: 'Print',
	create_patient: 'Create Patient',
	create_doctor: 'Create Doctor',
	create_service: 'Create Service',
	change_password: 'Change Password',
	logout: 'Exit',
	meron:'BET MERON',
	wala:'BET WALA',
	search:'SEARCH',
	today:'TODAY',
	last_day:'LAST DAY',
	this_week:'THIS WEEK',
	last_week:'LAST WEEK',
	this_month:'THIS MONTH',
	last_month:'LAST MONTH',
	yesterday:'YESTERDAY',
	login:'Login',
	home: 'Home',
	start_date: 'Start Date',
	end_date: 'End Date',
	all:'ALL',
};
