export default {
	home: 'ບ້ານ',
	// menu
	lotto: 'ຫວຍ',
	statement: 'ລາຍງານ',
	changepassword: 'ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ',
	logout:"ອອກ​ຈາກ​ລະ​ບົບ",
	// submenu
	winResult: 'ຊະນະຜົນໄດ້ຮັບ',
	lottoResult: 'ຜົນຫວຍ',
	withdrawStatement: 'ຖອນ​ຖະ​ແຫຼງ​ການ',
	depositStatement: 'ໃບແຈ້ງຍອດເງິນຝາກ',
	lottorule:'ກົດລະບຽບການຫວຍ',
	reportBetting:"ລາຍງານການພະນັນ",
	reportDeposit:"ລາຍງານເງິນຝາກ",
	reportWithdraw:"ລາຍ​ງານ​ການ​ຖອນ​ຕົວ"

};
