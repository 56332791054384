export default {
    welcome: "ຍິນດີຕ້ອນຮັບ",
    login:"ເຂົ້າ​ສູ່​ລະ​ບົບ",
    currencyEn:"ເງິນໂດລາ",
    currencyKm:"ເງິນ​ຕາ​ຂອງ​ຂະ​ແມ",
    bet_credit: "ເງິນເດີມພັນ",
    outstranding :"ເງິນແມ່ນການພະນັນ",
    last_game:"ເກມສຸດທ້າຍ",
    current_betting:"ການເດີມພັນໃນປະຈຸບັນ",
    previous_betting:"ການເດີມພັນທີ່ຜ່ານມາ",
    ticket:"ປີ້",
    total:"ທັງໝົດ",
    u_o:"ຂະຫນາດນ້ອຍ / ຂະຫນາດໃຫຍ່",
    min_bet :"ນ້ອຍທີ່ສຸດ",
    max_bet:"ໃຫຍ່ທີ່ສຸດ",
    range : "ຊ່ອງ",
    amount_bet:"ຈຳນວນເດີມພັນ",
    bet:"ເດີມພັນ",
    clear:"ຈະແຈ້ງ",
    result:"ຜົນໄດ້ຮັບ",
    statement:"ຖະແຫຼງການ",
    changePassword:"ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ",
    resultStatement:"ຖະແຫຼງການຜົນໄດ້ຮັບ",
    successChangePassword:"ສໍາເລັດການປ່ຽນແປງລະຫັດຜ່ານ",
    failChangePassword:"ປ່ຽນແປງບໍ່ສຳເລັດ",
    no_data:"ບໍ່​ມີ​ຂໍ້​ມູນ ...",
    betSmallType:"ຂະຫນາດນ້ອຍ",
    betBigType:"ໃຫຍ່",
    btnBetSmallType:"ຈິ",
    btnBetBigType:"ໃຫຍ່",
    not_enought_balance:"ບໍ່ມີຍອດເງິນພຽງພໍ",
    bet_over_limit:"ວາງເດີມພັນເກີນຂອບເຂດ"
};
