// import * as moment from 'moment';
// import {mapActions} from 'vuex'

export default {
    methods: {
        // ...mapActions('$_modules', ["fetchUserAccessPermission"]),
        // getAge(dob) {
        //     var a = moment();
        //     var b = moment(dob, 'YYYY');
        //     const age = a.diff(b, 'years');
        //     return age;
        // },
        // convertDate(date, format) {
        //     const defalut = 'DD-MM-YYYY';
        //     let newDate = new Date(date);
        //     newDate = moment(newDate).format(format ? format : defalut);
        //     return newDate;
        // },
        // convertDateTime(date, format = 'DD-MM-YYYY HH:mm:ss') {
        //     if (!date) return '';
        //     return moment(new Date(date)).format(format);
        // },
        // defaultPrice(price, format) {
        //     const newprice = price ? price : '';
        //     if (format) {
        //         return newprice + format;
        //     }
        //     return newprice + '$';
        // },
        // clearData(data) {
        //     for (const item in data) {
        //         switch (item) {
        //             case 'dob':
        //                 data[item] = '';
        //                 break;
        //             default:
        //                 data[item] = null;
        //         }
        //     }
        //     return data;
        // },
        // checkUserPermission(permission, arrayPermissions) {
        //     let objPermission = arrayPermissions.find(i => i.permission_name === permission);
        //     return !!objPermission;
        // }
    }
};