export default {
    search: 'ค้นหา',
    permission_name: 'ชื่ออนุญาต',
    role_name: 'ชื่อบทบาท',
    menu_name: 'ชื่อเมนู',
    description: 'คำอธิบาย',
    order: 'คำสั่ง',
    route_name: 'ชื่อเส้นทาง',
    url_name: 'ชื่อ url',
    icon: 'ไอคอน',
    parent: 'พ่อแม่',
    permission: 'การอนุญาต',
    visible: 'มองเห็นได้',
    user_name: 'ชื่อผู้ใช้',
    bank_account: 'บัญชีธนาคาร',
    user_role: 'บทบาทของผู้ใช้',
    password: 'รหัสผ่าน',
    newPassword:"รหัสผ่านใหม่",
    oldPassword:"รหัสผ่านเก่า",
    betCurrency:"$",
    overLimit:"เล่นต่ำกว่าหรือสูงกว่าขีดจำกัด"
};
