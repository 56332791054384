<!-- Main Layout -->
<!-- Do not include any other layout incase you want to change main layout (AppBar) -->
<template>
    <v-app id="inspire">
        <router-view></router-view>
    </v-app>
</template>

<script>
    export default {
        created() {
            this.connectSocket()
        },
        methods: {
            connectSocket() {
                this.$connectSocket(this.$cookies.get('l80token'))
            },
            onLogout() {
                this.$socket.disconnect()
                this.$cookies.remove('l80token')
                this.$router.push("/login").catch({})
            },
        },
    }
</script>

<style scoped>
</style>