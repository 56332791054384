/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
//
let request = new Request;
//
const fetchListResult = async ({ commit }, preData) => {
    commit('LOADING', true);
    const response = await request.post({
        url: '/getTv', data:preData
    });
    if (response.data.success) {
        commit('LIST_RESULT', response.data.data);
        commit('LOADING', false);
    }
};
const createFormURL = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.post({
        url: '/tv',
        data
    });
    if (response.data.status) {
        commit('LOADING', false);
        commit('LIST_RESULT', response.data.data);
        vm.$toastr.s("Successfully Saved")
    }else{
        vm.$toastr.e("Can not Save")
    }

};
const updateFormURL = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.post({
        url: '/tv/'+ data._id,
        data:data
    });
    if (response.data.success) {
        commit('UPDATE_CURRENT_DATA', response.data.data);
        commit('LOADING', false);
        vm.$toastr.s("Successfully Updated")
    }else{
        vm.$toastr.e("Can not Updated")
    }

};
const fetchCurrentList = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};
const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: '/tv/delete/'+ data._id,
        data:data
    });
    console.log("response.data",response.data);
    if (response.data) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s("Successfully Deleted")
    } else{
        vm.$toastr.e("Can not Delete")
    }
};
//
export default {
    fetchListResult,
    createFormURL,
    updateFormURL,
    fetchCurrentList,
    fetchPopUp,
    deleteData
};