export default {
	username: 'ชื่อผู้ใช้',
	password: 'รหัสผ่าน',
	login: 'เข้าสู่ระบบบัญชี',
	contact_us: 'ติดต่อเรา',
	//validation
	invalid: {
		username: 'ใส่ชื่อผู้ใช้ไม่ถูกต้อง!'
	},
	//required
	required: {
		username: 'กรุณากรอกชื่อผู้ใช้!',
		password: 'กรุณาใส่รหัสผ่านของคุณ!'
	},
	//error
	error: {
		login_failed: 'ชื่อผู้ใช้หรือรหัสผ่านของคุณไม่ปรากฏในระบบของเรา'
	}
};
