import Vue from 'vue'
import App from './App.vue'
import router from './services/router'
import Request from './services/axios.js';
import VueCookies from 'vue-cookies';
import VueHtmlToPaper from 'vue-html-to-paper';
import store from './services/store.js'
import vuetify from '@/plugins/vuetify'
import mixin from './mixin';
import VueToastr from 'vue-toastr';
import i18n from './services/i18n';
import { io } from "socket.io-client";
import VueConfetti from "vue-confetti";

import '@/assets/css/tailwind.css';
import '@mdi/font/css/materialdesignicons.css'

const options = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: ['css/print.css']
};

// set global component
Vue.mixin(mixin);
Vue.use(VueCookies);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueToastr, { defaultTimeout: 500 });
Vue.use(VueConfetti);

Vue.config.productionTip = false
Vue.prototype.$request = new Request();
//set global socket
Vue.prototype.$connectSocket = (token = Vue.$cookies.get('l80token')) => {
    if (token) {
            const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
                extraHeaders: {
                    "Content-Type": "application/json",
                    "Authorization": "bearer" + token
                }
            });
            socket.on("connect", () => {
                console.log('game socket: Connectd')
            });
            socket.on("connect_error", (err) => {
                console.log('socket: Message err == ', err);
            });
            socket.on('disconnect', () => {
                console.log(`socket: Disconnection`);
            });
            socket.on('error', (err) => {
                console.log('socket: Error connecting to server', err);
            });
            socket.on('reconnect', (number) => {
                console.log('socket: Reconnected to server', number);
            });
            socket.on('reconnect_attempt', () => {
                console.log('socket: Reconnect Attempt', 'alert problem');
            });
            socket.on('reconnecting', (number) => {
                console.log('socket: Reconnecting to server', number);
            });
            socket.on('reconnect_error', (err) => {
                console.log('socket: Reconnect Error', err);
            });
            socket.on('reconnect_failed', () => {
                console.log('socket: Reconnect failed', 'offline');
            });
            Vue.prototype.$socket = socket
        }
};
new Vue({
    router,
    i18n,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
