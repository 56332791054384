export default {
    permission: 'សិទ្ធអ្នកប្រើប្រាស់',
    role: 'ប្រភេទអ្នកប្រើប្រាស់',
    description: 'បរិយាយ',
    menu_name: 'ឈ្មោះ Menu',
    icon: 'រូបតំណាង',
    order: 'លេខលំដាប់',
    active: 'សកម្ម',
    actions: 'សកម្មភាព',
    balance: 'ទឹកប្រាក់',
    created_at: 'កាលបរិច្ឆេទ',
    bank_account: 'ព័ត៌មានគណនីធនាគារ',
    user_name: 'ឈ្មោះអ្នកប្រើប្រាស់',
    deposit_withdraw: 'ដក/ដាក់ប្រាក់',
    no :"ល.រ",
    game:"ហ្គេម",
    date:"កាលវរិច្ឆេទ",
    result:"លទ្ធផល",
    detail:"លំអិត",
    headtail:"ធំ / តូច",
    range:"ចន្លោះ",
    id:"លេខសំគាល់",
    account:"គណនី",
    bet:"ចាក់ចំនួន",
    type:"ចាក់ប្រភេទ",
    payout:"ផលសង",
    winLose:"ឈ្នះ / ចាញ់",
    oldBalance:"លុយចាស់",
    newBalance:"លុយថ្មី",
    amount: "លុយបានដក",
    small_large: "តូច / ធំ"
};
