export default {
	update: 'ອັບເດດ',
	delete: 'ລຶບ',
	delete_all: 'ລົບ​ທັງ​ຫມົດ',
	view: 'ເບິ່ງ',
	cancel: 'ຍົກເລີກ',
	yes: 'ແມ່ນແລ້ວ',
	save: 'ບັນທຶກ',
	detail: 'ລາຍລະອຽດ',
	create: 'ສ້າງ',
	upload_file: 'ອັບໂຫລດໄຟລ໌',
	clear: 'ຈະແຈ້ງ',
	filter: 'ການກັ່ນຕອງ',
	local_leng: 'ພາສາລາວ',
	create_invoice: 'ສ້າງໃບແຈ້ງໜີ້',
	print: 'ພິມ',
	change_password: 'ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ',
	logout: 'ອອກ​ຈາກ​ລະ​ບົບ',
	meron:'ສີແດງ',
	wala:'ສີຟ້າ',
	search:'ຊອກຫາ',
	today:'ມື້​ນີ້',
	last_day:'ມື້ສຸດທ້າຍ',
	this_week:'ອາ​ທິດ​ນີ້',
	last_week:'ອາ​ທິດ​ທີ່​ແລ້ວ',
	this_month:'ເດືອນ​ນີ້',
	last_month:'ເດືອນທີ່ຜ່ານມາ',
	yesterday: 'ມື້ວານນີ້',
	start_date: 'ວັນ​ທີ່​ເລີ່ມ',
	end_date: 'ວັນທີສິ້ນສຸດ',
	login:'ເຂົ້າ​ສູ່​ລະ​ບົບ',
	home:'ບ້ານ',
	all:'ທັງໝົດ',
};
