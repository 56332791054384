export default {
    permission: 'การอนุญาต',
    role: 'บทบาท',
    description: 'คำอธิบาย',
    menu_name: 'ชื่อเมนู',
    icon: 'ไอคอน',
    order: 'คำสั่ง',
    active: 'คล่องแคล่ว',
    actions: 'การกระทำ',
    balance: 'สมดุล',
    created_at: 'สร้างที่',
    bank_account: 'บัญชีธนาคาร',
    user_name: 'ชื่อผู้ใช้',
    deposit_withdraw: 'ฝาก/ถอน',
    no: "การนับ",
    game: "เกม",
    date: "วันที่",
    result: "ผลลัพธ์",
    detail: "รายละเอียด",
    headtail: "ใหญ่ / เล็ก",
    range: "ช่องว่าง",
    id: "ไอดี",
    account: "บัญชีผู้ใช้",
    bet: "เล่นหมายเลข",
    type: "ประเภทการเล่น",
    payout: "คืนสินค้า",
    winLose: "ชนะ / สูญเสีย",
    oldBalance: "ยอดคงเหลือเก่า",
    newBalance: "นิวบาลานซ์",
    amount: "จำนวน",
    small_large: "เล็ก / ใหญ่"
};
