export default {
	update: 'อัปเดต',
	delete: 'ลบ',
	delete_all: 'ลบทั้งหมด',
	view: 'ดู',
	cancel: 'ยกเลิก',
	yes: 'ใช่',
	save: 'บันทึก',
	detail: 'รายละเอียด',
	create: 'สร้าง',
	upload_file: 'อัพโหลดไฟล์',
	clear: 'แจ่มใส',
	filter: 'กรอง',
	local_leng: 'ภาษาไทย',
	create_invoice: 'สร้างใบแจ้งหนี้',
	print: 'พิมพ์',
	create_patient: 'បង្កើតអ្នកជម្ងឺ',
	create_doctor: 'បង្កើតវេជ្ជបណ្ឌិត',
	create_service: 'បង្កើតសេវាកម្ម',
	change_password: 'เปลี่ยนรหัสผ่าน',
	logout: 'ออกจากระบบ',
	meron:'สีแดง',
	wala:'สีฟ้า',
	search:'ค้นหา',
	today:'วันนี้',
	last_day:'วันสุดท้าย',
	this_week:'ในสัปดาห์นี้',
	last_week:'อาทิตย์ที่แล้ว',
	this_month:'เดือนนี้',
	last_month:'เดือนที่แล้ว',
	yesterday: 'เมื่อวาน',
	start_date: 'วันที่เริ่มต้น',
	end_date: 'วันที่สิ้นสุด',
	login:'เข้าสู่ระบบ',
	home:'บ้าน',
	all:'ทั้งหมด',
};
