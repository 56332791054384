export default {
	ticket_id : "เลขที่ตั๋ว",
	game_id: "เกมตัวเลข",
	date : "วันที่",
	type_betting : "ชนิดฉีด",
	bet_point : "คืนสินค้า",
	win_loss: "ชนะ/แพ้",
	cancel :"ยกเลิก",
	print:"พิมพ์"
};
