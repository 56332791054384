export default {
    welcome: "សូមស្វាគមន៌",
    login:"ចូលកំសាន្ត",
    currencyEn:"ដុល្លា",
    currencyKm:"រៀល",
    bet_credit: "លុយភ្នាល់",
    outstranding :"លុយកំពុងភ្នាល់",
    last_game:"លទ្ធផលចុងក្រោយ",
    current_betting:"ទើបភ្នាល់",
    previous_betting:"ភ្នាល់ដៃមុន",
    ticket:"សំបុត្រ",
    total:"សរុប",
    u_o:"តូច / ធំ",
    min_bet :"តូចបំផុត",
    max_bet:"ធំបំផុត",
    range : "ចន្លោះ",
    amount_bet:"ចំនួនចាក់",
    bet:"ចាក់",
    clear:"លុប",
    result:"លទ្ធផល",
    statement:"របាយការណ៌",
    changePassword:"ប្តូរពាក្យសម្ងាត់",
    resultStatement:"របាយការណ៌នៃលទ្ធផល",
    successChangePassword:"បានប្តូរពាក្យសម្ងាត់ដោយជោគជ័យ",
    failChangePassword:"ប្តូរពាក្យសម្ងាត់មិនជោគជ័យ",
    no_data:"គ្មានទិន្នន័យ ...",
    betSmallType:"តូច",
    betBigType:"ធំ",
    btnBetSmallType:"តូច",
    btnBetBigType:"ធំ",
    not_enought_balance:"ទឹកប្រាក់មិនគ្រប់គ្រាន់",
    bet_over_limit:"បានចាក់លើសចំនួនកំណត់"
};
