const requireRoute = require.context('.', false, /\.js$/);

const routes = [
    {
        path: '/',
        redirect: '/home'
    }
];

requireRoute.keys().forEach((fileName) => {
    if (fileName === './index.js') {
        return;
    }
    const route = requireRoute(fileName).default;
    routes.push(...route);
});

export default routes;
