export default {
	update: 'កែប្រែ',
	delete: 'លុប',
	delete_all: 'លុបទាំងអស់',
	view: 'មើល',
	cancel: 'បោះបង់',
	yes: 'យល់ព្រម',
	save: 'រក្សារទុក',
	detail: 'ពត៌មានលំអិត',
	create: 'បង្កើត',
	upload_file: 'ផ្ទុកឯកសារឡើង',
	clear: 'សម្អាត',
	filter: 'ត្រង',
	local_leng: 'English',
	create_invoice: 'បង្កើតវិក្កយបត្រ',
	print: 'បោះពុម្ព',
	create_patient: 'បង្កើតអ្នកជម្ងឺ',
	create_doctor: 'បង្កើតវេជ្ជបណ្ឌិត',
	create_service: 'បង្កើតសេវាកម្ម',
	change_password: 'ផ្លាស់ប្តូរពាក្យសម្ងាត់',
	logout: 'ចាកចេញ',
	meron:'ចាក់ក្រហម',
	wala:'ចាក់ខៀវ',
	search:'ស្វែងរក',
	today:'ថ្ងៃនេះ',
	last_day:'ថ្ងៃមុន',
	this_week:'សប្តាហ៍នេះ',
	last_week:'សប្តាហ៍មុន',
	this_month:'ខែនេះ',
	last_month:'ខែមុន',
	yesterday: 'ម្សិលមិញ',
	start_date: 'ចាប់ផ្តើម',
	end_date: 'បញ្ចប់',
	login:'ចូលប្រព័ន្ធ',
	home:'ទំព័រដើម',
	all:'ទាំងអស់',
};
