export default {
	ticket_id : "Ticket ID",
	game_id: "Game ID",
	date : "Date",
	type_betting : "Betting Type",
	bet_point : "Payout",
	win_loss: "Win/lose",
	cancel :"Cancel",
	print:"Print"
};
