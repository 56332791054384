export default {
    welcome: "ยินดีต้อนรับ",
    login:"เข้าสู่ระบบ",
    currencyEn:"ดอลลาร์",
    currencyKm:"เรียล",
    bet_credit: "เดิมพันเงิน",
    outstranding :"เงินคือการเดิมพัน",
    last_game:"ผลสุดท้าย",
    current_betting:"แค่เดิมพัน",
    previous_betting:"เดิมพันมือแรก",
    ticket:"ตั๋ว",
    total:"รวม",
    u_o:"เล็ก / ใหญ่",
    min_bet :"เล็กที่สุด",
    max_bet:"ใหญ่ที่สุด",
    range : "ช่องว่าง",
    amount_bet:"จำนวนการเล่น",
    bet:"เล่น",
    clear:"ลบ",
    result:"ผล",
    statement:"รายงาน",
    changePassword:"เปลี่ยนรหัสผ่าน",
    resultStatement:"รายงานผลการปฏิบัติงาน",
    successChangePassword:"เปลี่ยนรหัสผ่านสำเร็จ",
    failChangePassword:"ไม่สามารถเปลี่ยนรหัสผ่าน",
    no_data:"ไม่มีข้อมูล ...",
    betSmallType:"เล็ก",
    betBigType:"ใหญ่",
    btnBetSmallType:"เล็ก",
    btnBetBigType:"ใหญ่",
    not_enought_balance:"สมดุลไม่พอ",
    bet_over_limit:"เดิมพันเกินขีดจำกัด"
};
