export default {
	home: 'บ้าน',
	// menu
	lotto: 'ล็อตโต้',
	statement: 'คำแถลง',
	changepassword: 'เปลี่ยนรหัสผ่าน',
	logout:"ออกจาก",
	// submenu
	winResult: 'ผลการชนะ',
	lottoResult: 'ผล',
	withdrawStatement: 'รายงานการถอนเงิน',
	depositStatement: 'รายงานการฝากเงิน',
	lottorule:'ช่วย',
	reportBetting:"รายงานการเดิมพัน",
	reportDeposit:"รายงานการฝากเงิน",
	reportWithdraw:"รายงานการถอนเงิน"

};
