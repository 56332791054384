export default {
    permission: 'ການອະນຸຍາດ',
    role: 'ບົດບາດ',
    description: 'ລາຍລະອຽດ',
    menu_name: 'ຊື່​ເມ​ນູ',
    icon: 'ໄອຄອນ',
    order: 'ຄໍາສັ່ງ',
    active: 'ເຄື່ອນໄຫວ',
    actions: 'ການກະທໍາ',
    balance: 'ຍອດເງິນ',
    created_at: 'ສ້າງຢູ່',
    bank_account: 'ບັນ​ຊີ​ທະ​ນາ​ຄານ',
    user_name: 'ຊື່ຜູ້ໃຊ້',
    deposit_withdraw: 'ຝາກ/ຖອນ',    
    no :"ເລກ",
    game:"ເກມ",
    date:"ວັນທີ",
    result:"ຜົນໄດ້ຮັບ",
    detail:"ລາຍລະອຽດ",
    headtail:"ຂະຫນາດໃຫຍ່ / ຂະຫນາດນ້ອຍ",
    range:"ຊ່ອງ",
    id:"ไอดี",
    account:"ບັນຊີ",
    bet:"ຫຼິ້ນເລກ",
    type:"ປະເພດຫຼິ້ນ",
    payout:"ກັບຄືນ",
    winLose:"ຊະນະ / ສູນເສຍ",
    oldBalance:"ຍອດເງິນເກົ່າ",
    newBalance:"ຍອດເງິນໃໝ່",
    amount: "ຈໍາ​ນວນ",
    small_large: "ຂະຫນາດນ້ອຍ / ໃຫຍ່"
};
